// import Router from 'vue-router'
import axios from 'axios'

import {
	setStorage,
	getStorage,
	hy_wms_prt_name
} from '@/utils/Storage.js';

import {
	Loading,
	Notification,
	MessageBox,
	Message
} from 'element-ui'; //项目已经全局引入element的话可以不单独引入

// import { getFileType } from '@/utils/utils.js';

// import {
// 		exportPdfByBarCode
// 	} from '@/utils/pdfExport.js';


//pdfjsLib
var pdfjsLib = window['pdfjs-dist/build/pdf'] || window.pdfjsLib || {};
// pdfjsLib.GlobalWorkerOptions.workerSrc = '/lib/pdfjs-2.12.313-dist/build/pdf.worker.js';
// pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
try {
	pdfjsLib.GlobalWorkerOptions.workerSrc = '/lib/pdfjs-2.12.313-dist/build/pdf.worker.js';
} catch (e) {
	//TODO handle the exception
	console.log(e);
}

let HY_BASEURL = "";
if (process.env.NODE_ENV === 'development') {
	// dev 开发 run dev
	HY_BASEURL = "/baseUrl";
} else {
	// build 线上和测试 run build
	HY_BASEURL = "";
}

let downLoadCLodopPageUrl = "/#/Frame/downloadCLodop";

let loading //定义loading变量

function startLoading() { //使用Element loading-start 方法
	loading = Loading.service({
		lock: true,
		text: 'loading……',
		background: 'rgba(0, 0, 0, 0.5)'
	})
}

function endLoading() { //使用Element loading-close 方法
	loading.close()
}
// alert(1);
let LODOP = null;

const Axios = axios.create({
	// baseURL: 'https://some-domain.com/api/',
	timeout: 60000,
	// headers: {'X-Custom-Header': 'foobar'}
	// 和后端 Content-Type 统一，跨域有option请求
	// headers:{'Content-Type':'application/x-www-form-urlencoded'}
	// headers: {'Content-Type':'application/json'}
	// headers: {'Content-Type':'application/json; charset=utf-8'} //默认
});

//coldop 是否支持浏览器
function isSupport() {
	var ua = navigator.userAgent;
	var isIE = !!(ua.match(/MSIE/i)) || !!(ua.match(/Trident/i));
	var isMac = /macintosh|mac os x/i.test(ua);
	var isPhone = /mobile/i.test(ua);
	let tip_msg = "支持";
	let support = true;
	if (isIE || isMac || isPhone) {
		tip_msg = "CLodop暂时不支持";
		if (isPhone) {
			tip_msg = '打印控件CLodop暂时不支持移动端，请使用PC端谷歌Chrome浏览器打印';
		}
		if (isMac) {
			tip_msg = '打印控件CLodop暂时不支持macOS系统，请使用其他系统的谷歌Chrome浏览器打印';
		}
		if (isIE) {
			tip_msg = '打印控件CLodop暂时不支持IE浏览器，请使用谷歌Chrome浏览器打印';
		}
		Message({
			message: tip_msg,
			// duration: 3000,
			type: 'warning'
		});
		// return;
		support = false;
	}

	return {
		support: support,
		noMsg: tip_msg
	}
}

function getLodop() {
	// if(LODOP){
	// 	return LODOP;
	// }
	// var strHtmInstall =
	// 	"<br><font color='#FF00FF'>打印控件未安装!点击这里<a href='/c-lodop/install_lodop32.exe' target='_self'>执行安装</a>,安装后请刷新页面或重新进入。</font>";
	// var strHtmUpdate =
	// 	"<br><font color='#FF00FF'>打印控件需要升级!点击这里<a href='/c-lodop/install_lodop32.exe' target='_self'>执行升级</a>,升级后请重新进入。</font>";
	// var strHtm64_Install =
	// 	"<br><font color='#FF00FF'>打印控件未安装!点击这里<a href='/c-lodop/install_lodop64.exe' target='_self'>执行安装</a>,安装后请刷新页面或重新进入。</font>";
	// var strHtm64_Update =
	// 	"<br><font color='#FF00FF'>打印控件需要升级!点击这里<a href='/c-lodop/install_lodop64.exe' target='_self'>执行升级</a>,升级后请重新进入。</font>";
	// var strHtmFireFox =
	// 	"<br><br><font color='#FF00FF'>（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）</font>";
	// var strHtmChrome = "<br><br><font color='#FF00FF'>(如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装）</font>";
	var strCLodopInstall_1 =
		"Web打印服务CLodop未安装启动，点击这里<a href='/c-lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>下载执行安装</a>";
	var strCLodopInstall_2 = "（若此前已安装过，可<a href='CLodop.protocol:setup' target='_self'>点这里直接再次启动</a>）";
	var strCLodopInstall_3 = "<br />成功后请刷新本页面。";
	var strCLodopUpdate =
		"<span >Web打印服务CLodop需升级!点击这里<a href='/c-lodop/CLodop_Setup_for_Win32NT.exe' target='_self'>执行升级</a>,升级后请刷新页面。</span>";
	// var LODOP;
	try {
		//浏览器是否支付clodop
		let SupportMsg = isSupport();
		if (!SupportMsg.support) {
			return;
		}

		if (needCLodop()) { //支持的浏览器及版本
			try { //旧版
				LODOP = getCLodop();
			} catch (err) { //新版
				console.log('err', err);
				try {
					LODOP = window.CLODOP2015_7028;
					console.log('LODOP', LODOP);
				} catch (err2) {
					console.log('err2', err2);
				}
			}

			// if (!LODOP && CLodopJsState !== "complete") {
			// 	if (CLodopJsState == "loading"){
			// 		// alert("网页还没下载完毕，请稍等一下再操作.");
			// 		MessageBox({
			// 			title:"提示",
			// 			message:"网页还没下载完毕，请稍等一下再操作.",
			// 			dangerouslyUseHTMLString:true,
			// 			type:"warning",
			// 			showCancelButton: true,
			// 		}).then(action => {

			// 		});
			// 	} else {
			// 		// alert("没有加载CLodop的主js，请先调用loadCLodop过程.");
			// 		MessageBox({
			// 			title:"提示",
			// 			message:"没有加载CLodop的主js，请先调用loadCLodop过程.",
			// 			dangerouslyUseHTMLString:true,
			// 			type:"warning",
			// 			showCancelButton: true,
			// 		}).then(action => {

			// 		});
			// 	}
			// 	return;
			// }
			if (!LODOP) { //如果LODOP不存在

				console.log('CLodopJsState', CLodopJsState); //旧加载方法
				console.log('LoadJsState', LoadJsState); //新加载方法
				// if (!LODOP && CLodopJsState !== "complete") {

				if (CLodopJsState == "loading" || LoadJsState == 'loadingA' || LoadJsState == 'loadingB') {
					// alert("网页还没下载完毕，请稍等一下再操作.");
					MessageBox({
						title: "提示",
						message: "网页还没下载完毕，请稍等一下再操作.",
						// dangerouslyUseHTMLString: true,
						type: "warning",
						showCancelButton: true,
					}).then(action => {
						// window.location.href = downLoadCLodopPageUrl;
					});
					return;
				} else {
					// alert("没有加载CLodop的主js，请先调用loadCLodop过程.");
					// return;
					// document.body.innerHTML = strCLodopInstall_1 + (CLodopIsLocal ? strCLodopInstall_2 : "") +
					// 	strCLodopInstall_3 + document.body.innerHTML;
					// document.body.innerHTML = strCLodopInstall_1 + strCLodopInstall_2 +
					// 	strCLodopInstall_3 + document.body.innerHTML;
					// alert("请去安装页安装CLODOP,安装完请刷新本页面");
					let html = strCLodopInstall_1 + strCLodopInstall_2 + strCLodopInstall_3 +
						"<br />还可以点击‘确定’到CLODOP下载页面";
					MessageBox({
						title: "提示",
						message: html,
						dangerouslyUseHTMLString: true,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "去下载页",
						cancelButtonText: "取消",
						confirmButtonClass: "el-icon-right"
					}).then(action => {
						window.location.href = downLoadCLodopPageUrl;
					});
					return;
				}

				// }


			} else { //如果lodop存在
				if (CLODOP.CVERSION < "4.1.2.7") {
					// document.body.innerHTML = strCLodopUpdate + document.body.innerHTML;
					// alert("请去安装页安装升级CLODOP,安装完请刷新本页面");
					let html = strCLodopUpdate + "<br />还可以点击‘确定’到CLODOP下载页面";
					MessageBox({
						title: "提示",
						message: html,
						dangerouslyUseHTMLString: true,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "去下载页",
						cancelButtonText: "取消",
						confirmButtonClass: "el-icon-right"
					}).then(action => {
						window.location.href = downLoadCLodopPageUrl;
					});
					return;
				}
				// if (oEMBED && oEMBED.parentNode)
				// 	oEMBED.parentNode.removeChild(oEMBED); //清理旧版无效元素
				// if (oOBJECT && oOBJECT.parentNode)
				// 	oOBJECT.parentNode.removeChild(oOBJECT);
			}
		} else { //不支持的浏览器及版本
			// alert("请使用谷歌浏览器打印");
			// var is64IE = isIE && !!(ua.match(/x64/i));
			// //==如果页面有Lodop就直接使用,否则新建:==
			// if (oOBJECT || oEMBED) {
			// 	if (isIE)
			// 		LODOP = oOBJECT;
			// 	else
			// 		LODOP = oEMBED;
			// } else if (!CreatedOKLodopObject) {
			// 	LODOP = document.createElement("object");
			// 	LODOP.setAttribute("width", 0);
			// 	LODOP.setAttribute("height", 0);
			// 	LODOP.setAttribute("style", "position:absolute;left:0px;top:-100px;width:0px;height:0px;");
			// 	if (isIE)
			// 		LODOP.setAttribute("classid", "clsid:2105C259-1E0C-4534-8141-A753534CB4CA");
			// 	else
			// 		LODOP.setAttribute("type", "application/x-print-lodop");
			// 	document.documentElement.appendChild(LODOP);
			// 	CreatedOKLodopObject = LODOP;
			// } else
			// 	LODOP = CreatedOKLodopObject;
			// //==Lodop插件未安装时提示下载地址:==
			if ((!LODOP) || (!LODOP.VERSION)) {
				if (ua.indexOf('Chrome') >= 0) {
					// document.body.innerHTML = strHtmChrome + document.body.innerHTML;
					// alert("如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行以上安装");
					MessageBox({
						title: "提示",
						message: "如果此前正常，仅因浏览器升级或重安装而出问题，需重新执行安装<br />还可以点击‘确定’到CLODOP下载页面",
						dangerouslyUseHTMLString: true,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "去下载页",
						cancelButtonText: "取消",
						confirmButtonClass: "el-icon-right"
					}).then(action => {
						window.location.href = downLoadCLodopPageUrl;
					});
					return;
				} else if (ua.indexOf('Firefox') >= 0) {
					// alert("（注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它）");
					// document.body.innerHTML = strHtmFireFox + document.body.innerHTML;
					// document.body.innerHTML = (is64IE ? strHtm64_Install : strHtmInstall) + document.body.innerHTML;
					// document.body.innerHTML = ( strHtmInstall) + document.body.innerHTML;
					MessageBox({
						title: "提示",
						message: "注意：如曾安装过Lodop旧版附件npActiveXPLugin,请在【工具】->【附加组件】->【扩展】中先卸它<br />还可以点击‘确定’到CLODOP下载页面",
						dangerouslyUseHTMLString: true,
						type: "warning",
						showCancelButton: true,
						confirmButtonText: "去下载页",
						cancelButtonText: "取消",
						confirmButtonClass: "el-icon-right"
					}).then(action => {
						window.location.href = downLoadCLodopPageUrl;
					});
					return;
				}
				// return LODOP;
			}
		}
		// if (LODOP.VERSION < "6.2.2.6") {
		// 	if (!needCLodop()) {
		// 		// document.body.innerHTML = (is64IE ? strHtm64_Update : strHtmUpdate) + document.body.innerHTML;
		// 		// document.body.innerHTML = (strHtmUpdate) + document.body.innerHTML;
		// 		// 打印控件需要升级!点击这里<a href='/c-lodop/install_lodop32.exe' target='_self'>执行升级</a>,升级后请重新进入。
		// 	}
		// }
		//===如下空白位置适合调用统一功能(如注册语句、语言选择等):==
		LODOP.SET_LICENSES("", "C78877BA980B5CFD808A9C688B1A1CFD410", "", "");
		// alert("SET_LICENSES执行了");
		//=======================================================
		return LODOP;
	} catch (err) {
		// alert("getLodop出错:" + err);

		//浏览器是否支付clodop
		let SupportMsg = isSupport();
		if (!SupportMsg.support) {
			return;
		}

		MessageBox({
			title: "提示",
			message: "您可以点击‘确定’到CLODOP下载页面~<br />getLodop出错:" + err,
			dangerouslyUseHTMLString: true,
			type: "warning",
			showCancelButton: true,
			confirmButtonText: "去下载页",
			cancelButtonText: "取消",
			confirmButtonClass: "el-icon-right"
		}).then(action => {
			window.location.href = downLoadCLodopPageUrl;
		});
		return;
	}
}


// 打印PDF url 单个-批次打印
// export async function priterPdfUrlAndId(priterInitName, url_old, j, type, PRINTER_INDEX, PAGESIZE_TYPE,IS_PREVIEW) {
export async function priterPdfUrlAndId(option) {
	//初始化
	let priterInitName = option.Prt_Init_Name; //打印任务名称
	let url_old = option.Item_Data; //需要打印的数据
	let j = option.Index; //打印的序号
	let type = option.Type; //是否重新打印：2--重新打印，其他--首次打印
	let PRINTER_INDEX = option.PRINTER_INDEX; //指定打印机：打印机序号
	let PAGESIZE_TYPE = option.PAGESIZE_TYPE; //打印模式：缩放还是自适应
	let IS_PREVIEW = option.IS_PREVIEW; //预览打印：是否

	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	LODOP = getLodop();
	// if (LODOP) {
	// 	startLoading();
	// }
	let reObj = {};
	// for (let j = 0; j < list.length; j++) {
	// let url_old = list[j];
	let base64 = null;
	let printSeq = '';
	let base64Data = {};
	let printType = '';
	// url_old.url = 'http://sys.anmeigroup.com/label/0845758ccd1a7691ecbdf6273de61378';
	// url_old.url = 'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/attachment/_5842420-20220301-4-nd_1032934171779751938_1646201571658_Label-1Z47WV04P297799668.pdf';
	// url_old.url ='https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/attachment/_5842420-20220301-3-nd_1032934171779751938_1646374498836_20220114000051646373847221.pdf';
	try {
		if (type == '2') { //重复打印
			base64Data = await axiosDownloadPDF_ID(url_old.url, url_old.id,
				'/wh/bizWhMySendExpress/v2/repeat/print/label');
			base64 = base64Data.data.pdfBase64;
			printSeq = base64Data.data.printSeq;
			printType = base64Data.data.printType;
		} else {
			base64Data = await axiosDownloadPDF_ID(url_old.url, url_old.id,
				'/wh/bizWhMySendExpress/v2/print/label');
			base64 = base64Data.data.pdfBase64;
			printSeq = base64Data.data.printSeq;
			printType = base64Data.data.printType;
			if (base64Data.data.printCount > 1) {
				console.log(base64Data);
				Notification({
					message: '第 ' + j + "个,已打印,已自动过滤；",
					// duration: 0,
					type: 'error'
				});
				url_old.error = '1';
				url_old.index = j;
				reObj = url_old;
				// }
				return reObj;
			}
		}
		// console.log('base64Data', base64Data);
	} catch (err) {
		console.log(err);
		// endLoading();

		Notification({
			message: '第 ' + j + "个,read error!",
			// duration: 0,
			type: 'error'
		});
		url_old.error = '1';
		url_old.index = j;
		reObj = url_old;
		// }
		return reObj;
	}

	// reObj = await priterPdfBase64(priterInitName, url_old, base64, printSeq, j, PRINTER_INDEX, PAGESIZE_TYPE,IS_PREVIEW);
	reObj = await priterPdfBase64({
		Prt_Init_Name: option.Prt_Init_Name,
		Item_Data: option.Item_Data,
		base64: base64,
		printSeq: printSeq,
		printType: printType,
		Index: option.Index,
		Type: option.Type,
		PRINTER_INDEX: option.PRINTER_INDEX,
		PAGESIZE_TYPE: option.PAGESIZE_TYPE,
		IS_PREVIEW: option.IS_PREVIEW,
	});
	return reObj;
}
// 打印PDF base64 单个-批次打印
// export async function priterPdfBase64(priterInitName, url_old, base64_or_url, printSeq, j, PRINTER_INDEX, PAGESIZE_TYPE,IS_PREVIEW) {
export async function priterPdfBase64(option) {

	//初始化
	// let priterInitName = option.Prt_Init_Name; //打印任务名称
	// let url_old = option.Item_Data; //需要打印的数据
	let base64_or_url = option.base64;//base64
	// let printSeq = option.printSeq;//序列号
	let printType = option.printType; //pdf还是zpl格式
	// let j = option.Index; //打印的序号
	// // let type = option.Type; //是否重新打印：2--重新打印，其他--首次打印
	// let PRINTER_INDEX = option.PRINTER_INDEX; //指定打印机：打印机序号
	// let PAGESIZE_TYPE = option.PAGESIZE_TYPE; //打印模式：缩放还是自适应
	// let IS_PREVIEW = option.IS_PREVIEW; //预览打印：是否
	let reObj = {};
	if ('zpl' == printType) {
		console.log('zpl');
		reObj = await priterZPL_Data(option);
	} else { //pdf
		// let FileType = getFileType(base64_or_url);
		// if('image'==FileType){//图片
		// 	reObj = await priterIMG_Data(option);
		// } else {//pdf
		// 	reObj = await priterPDF_Data(option);
		// }
		reObj = await priterPDF_Data(option);
	}

	//浏览器是否支付clodop
	// let SupportMsg = isSupport();
	// if (!SupportMsg.support) {
	// 	return;
	// }

	// LODOP = getLodop();
	// // if (LODOP) {
	// // 	startLoading();
	// // }
	// let index = j;
	// if (null == index) {
	// 	index = url_old.sku;
	// }
	// let reObj = {};
	// if (base64_or_url) {
	// 	// 打印
	// 	LODOP.PRINT_INIT(index + ':' + priterInitName);
	// 	if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
	// 		PRINTER_INDEX = -1;
	// 	}
	// 	//指定打印机
	// 	LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);

	// 	// let FileType = getFileType(base64_or_url);

	// 	// // console.log('base64_or_url',base64_or_url);

	// 	// if ('image' == FileType) { //图片
	// 	// 	//打印图片
	// 	// 	// ADD_PRINT_IMAGE(Top,Left,Width,Height,strHtmlContent)

	// 	// 	LODOP.ADD_PRINT_IMAGE(0, '0.12in', '100%', '100%', `<img src='${base64_or_url}' />`);
	// 	// 	// LODOP.ADD_PRINT_IMAGE(0,0,'100%','100%',URL);
	// 	// 	// LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
	// 	// } else {
	// 	// 0.1 英寸=2.54 毫米 = 9.6像素
	// 	// 10px=
	// 	//左边偏移，防止左边截取 1pt=1/72英寸=0.013888 10pt =10/72in=0.13888in
	// 	//5px->0.15in = 5px ->15px
	// 	LODOP.ADD_PRINT_PDF(0, '0.12in', '100%', '100%', base64_or_url);
	// 	// }

	// 	// var PRINTSETUP_SIZE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_SIZE_HEIGHT",1);
	// 	// console.log('PRINTSETUP_SIZE_HEIGHT',PRINTSETUP_SIZE_HEIGHT);

	// 	// var PRINTSETUP_PAGE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_PAGE_HEIGHT",1);
	// 	// console.log('PRINTSETUP_PAGE_HEIGHT',PRINTSETUP_PAGE_HEIGHT);
	// 	let typeMark = '';
	// 	if (1 == PAGESIZE_TYPE) {
	// 		typeMark = '';
	// 	} else if (2 == PAGESIZE_TYPE) {
	// 		typeMark = ',';
	// 	} else if (3 == PAGESIZE_TYPE) {
	// 		typeMark = '.';
	// 	} else {
	// 		typeMark = ';';
	// 	}
	// 	//设置字体
	// 	// FontName的值： 字符型，与操作系统字体名一致，缺省是“宋体”。
	// 	// LODOP.SET_PRINT_STYLE('FontName', 'Sans-serif');
	// 	LODOP.SET_PRINT_STYLE('FontName', 'Helvetica-Bold');
	// 	// FontSize的值：数值型，单位是pt，缺省值是9，可以含小数，如13.5。
	// 	// 是一个标准的长度单位,1pt=1/72英寸,用于印刷业
	// 	// LODOP.SET_PRINT_STYLE('FontSize', 11);
	// 	// FontColor的值：整数或字符型，整数时是颜色的十进制RGB值；字符时是超文本颜色值，可以是“#”加三色16进制值组合，也可以是英文颜色名；
	// 	LODOP.SET_PRINT_STYLE('FontColor', '#000000');
	// 	// Bold的值：数字型，1代表粗体，0代表非粗体，缺省值是0。
	// 	LODOP.SET_PRINT_STYLE('Bold', 1);
	// 	// ItemType的值：数字型，0--普通项 1--页眉页脚 2--页号项 3--页数项 4--多页项 
	// 	// 缺省（不调用本函数时）值0。普通项只打印一次；页眉页脚项则每页都在固定位置重复打印；页号项和页数项是特殊的页眉页脚项，其内容包含当前页号和全部页数；多页项每页都打印，直到把内容打印完毕，打印时在每页上的位置和区域大小固定一样（多页项只对纯文本有效）
	// 	//               在页号或页数对象的文本中，有两个特殊控制字符：
	// 	// “#”特指“页号”，“&”特指“页数”。
	// 	// LODOP.SET_PRINT_STYLE('ItemType',1);
	// 	LODOP.SET_PRINT_STYLE('FontSize', 13);
	// 	LODOP.ADD_PRINT_TEXT('6in', '0.2in', "100%", "13pt", url_old.sku + " * " + url_old.quantity);
	// 	LODOP.SET_PRINT_STYLE('FontSize', 10);
	// 	LODOP.ADD_PRINT_TEXT('6in', '2.4in', "100%", "10pt", printSeq + typeMark);

	// 	if (1 == PAGESIZE_TYPE) {
	// 		// LODOP.SET_PRINT_STYLEA(0,"PDFScalMode",2);
	// 		// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', 0, '');
	// 		// LODOP.SET_PRINT_PAGESIZE(3, 0, 0, '');
	// 		// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
	// 		// LODOP.ADD_PRINT_TEXT('5.7in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
	// 		// LODOP.ADD_PRINT_TEXT('5.7in', '2.3in', "100%", "11pt", printSeq);

	// 		// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Height");
	// 		LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "91%");
	// 	} else if (2 == PAGESIZE_TYPE) {

	// 		// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', '5.8in', '');
	// 		// LODOP.ADD_PRINT_TEXT('5.45in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
	// 		// LODOP.ADD_PRINT_TEXT('5.45in', '2.3in', "100%", "11pt", printSeq);
	// 		// LODOP.SET_PRINT_STYLEA(0, "PDFScalMode", 0);
	// 		// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

	// 	} else {
	// 		// LODOP.ADD_PRINT_TEXT('6in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
	// 		// LODOP.ADD_PRINT_TEXT('6in', '2.3in', "100%", "11pt", printSeq);

	// 		// LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
	// 		LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
	// 		// 

	// 		// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Full-Page");
	// 	}
	// 	// LODOP.ADD_PRINT_PDF(0, 0, '4in', '6in', base64);

	// 	// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
	// 	// let PRT_RES = false;
	// 	if (IS_PREVIEW) {
	// 		LODOP.PREVIEW();
	// 		Notification({
	// 			message: index + ',Opening preview...',
	// 			type: 'success'
	// 		});
	// 	} else {
	// 		// LODOP.PREVIEW();
	// 		let PRT_RES = LODOP.PRINT();
	// 		console.log('PRT_RES', PRT_RES);
	// 		if (!PRT_RES) {
	// 			url_old.error = '3';
	// 			// reObj = url_old;
	// 			Notification({
	// 				message: index + ',print error;code:' + url_old.error,
	// 				// duration: 0,
	// 				type: 'warning'
	// 			});
	// 		} else {
	// 			Notification({
	// 				message: index + ',printing...',
	// 				type: 'success'
	// 			});
	// 		}
	// 	}

	// 	try {
	// 		//是否是URL
	// 		let IS_URL = /^(https:\/\/)/.test(base64_or_url.substring(0, 10));
	// 		if (!IS_URL) {
	// 			//读取pdf页数
	// 			let pdf = {};
	// 			pdf = await pdfjsLib.getDocument({
	// 				data: getUint8Array(base64_or_url)
	// 			}).promise.catch(err => {
	// 				console.log(err);
	// 				// endLoading();
	// 			});
	// 			console.log('pdf', pdf);
	// 			let numPages = pdf.numPages;
	// 			url_old.pdfNum = numPages;
	// 		}

	// 	} catch (e) {
	// 		//TODO handle the exception
	// 		console.log(e);
	// 	}


	// 	// reObj = url_old;
	// 	// endLoading();


	// } else {
	// 	// endLoading();
	// 	url_old.error = '2';
	// 	// reObj = url_old;
	// 	Notification({
	// 		message: index + ',print error;code:' + url_old.error,
	// 		// duration: 0,
	// 		type: 'warning'
	// 	});
	// }
	// url_old.index = index;
	// reObj = url_old;
	// // }
	return reObj;
}

//pdf，格式
export async function priterPDF_Data(option) {
	//初始化
	let priterInitName = option.Prt_Init_Name; //打印任务名称
	let url_old = option.Item_Data; //需要打印的数据
	let base64_or_url = option.base64; //base64
	let printSeq = option.printSeq; //序列号
	// let printType = option.printType;//pdf还是zpl格式
	let j = option.Index; //打印的序号
	// let type = option.Type; //是否重新打印：2--重新打印，其他--首次打印
	let PRINTER_INDEX = option.PRINTER_INDEX; //指定打印机：打印机序号
	let PAGESIZE_TYPE = option.PAGESIZE_TYPE; //打印模式：缩放还是自适应
	let IS_PREVIEW = option.IS_PREVIEW; //预览打印：是否

	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	LODOP = getLodop();
	// if (LODOP) {
	// 	startLoading();
	// }
	let index = j;
	if (null == index) {
		index = url_old.sku;
	}
	let reObj = {};
	if (base64_or_url) {
		// 打印
		LODOP.PRINT_INIT(index + ':' + priterInitName);
		if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
			PRINTER_INDEX = -1;
		}
		//指定打印机
		LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);

		// let FileType = getFileType(base64_or_url);

		// // console.log('base64_or_url',base64_or_url);

		// if ('image' == FileType) { //图片
		// 	//打印图片
		// 	// ADD_PRINT_IMAGE(Top,Left,Width,Height,strHtmlContent)

		// 	LODOP.ADD_PRINT_IMAGE(0, '0.12in', '100%', '100%', `<img src='${base64_or_url}' />`);
		// 	// LODOP.ADD_PRINT_IMAGE(0,0,'100%','100%',URL);
		// 	// LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
		// } else {
		// 0.1 英寸=2.54 毫米 = 9.6像素
		// 10px=
		//左边偏移，防止左边截取 1pt=1/72英寸=0.013888 10pt =10/72in=0.13888in
		//5px->0.15in = 5px ->15px
		LODOP.ADD_PRINT_PDF(0, '0.12in', '100%', '100%', base64_or_url);
		// }

		// var PRINTSETUP_SIZE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_SIZE_HEIGHT",1);
		// console.log('PRINTSETUP_SIZE_HEIGHT',PRINTSETUP_SIZE_HEIGHT);

		// var PRINTSETUP_PAGE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_PAGE_HEIGHT",1);
		// console.log('PRINTSETUP_PAGE_HEIGHT',PRINTSETUP_PAGE_HEIGHT);
		let typeMark = '';
		if (1 == PAGESIZE_TYPE) {
			typeMark = '';
		} else if (2 == PAGESIZE_TYPE) {
			typeMark = ',';
		} else if (3 == PAGESIZE_TYPE) {
			typeMark = '.';
		} else {
			typeMark = ';';
		}
		//设置字体
		// FontName的值： 字符型，与操作系统字体名一致，缺省是“宋体”。
		// LODOP.SET_PRINT_STYLE('FontName', 'Sans-serif');
		LODOP.SET_PRINT_STYLE('FontName', 'Helvetica-Bold');
		// FontSize的值：数值型，单位是pt，缺省值是9，可以含小数，如13.5。
		// 是一个标准的长度单位,1pt=1/72英寸,用于印刷业
		// LODOP.SET_PRINT_STYLE('FontSize', 11);
		// FontColor的值：整数或字符型，整数时是颜色的十进制RGB值；字符时是超文本颜色值，可以是“#”加三色16进制值组合，也可以是英文颜色名；
		LODOP.SET_PRINT_STYLE('FontColor', '#000000');
		// Bold的值：数字型，1代表粗体，0代表非粗体，缺省值是0。
		LODOP.SET_PRINT_STYLE('Bold', 1);
		// ItemType的值：数字型，0--普通项 1--页眉页脚 2--页号项 3--页数项 4--多页项 
		// 缺省（不调用本函数时）值0。普通项只打印一次；页眉页脚项则每页都在固定位置重复打印；页号项和页数项是特殊的页眉页脚项，其内容包含当前页号和全部页数；多页项每页都打印，直到把内容打印完毕，打印时在每页上的位置和区域大小固定一样（多页项只对纯文本有效）
		//               在页号或页数对象的文本中，有两个特殊控制字符：
		// “#”特指“页号”，“&”特指“页数”。
		// LODOP.SET_PRINT_STYLE('ItemType',1);
		LODOP.SET_PRINT_STYLE('FontSize', 13);
		LODOP.ADD_PRINT_TEXT('6in', '0.2in', "100%", "13pt", url_old.sku + " * " + url_old.quantity);
		LODOP.SET_PRINT_STYLE('FontSize', 10);
		LODOP.ADD_PRINT_TEXT('6in', '2.4in', "100%", "10pt", printSeq + typeMark);

		if (1 == PAGESIZE_TYPE) {
			// LODOP.SET_PRINT_STYLEA(0,"PDFScalMode",2);
			// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', 0, '');
			// LODOP.SET_PRINT_PAGESIZE(3, 0, 0, '');
			// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
			// LODOP.ADD_PRINT_TEXT('5.7in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
			// LODOP.ADD_PRINT_TEXT('5.7in', '2.3in', "100%", "11pt", printSeq);

			// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Height");
			LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "91%");
		} else if (2 == PAGESIZE_TYPE) {

			// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', '5.8in', '');
			// LODOP.ADD_PRINT_TEXT('5.45in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
			// LODOP.ADD_PRINT_TEXT('5.45in', '2.3in', "100%", "11pt", printSeq);
			// LODOP.SET_PRINT_STYLEA(0, "PDFScalMode", 0);
			// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

		} else {
			// LODOP.ADD_PRINT_TEXT('6in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
			// LODOP.ADD_PRINT_TEXT('6in', '2.3in', "100%", "11pt", printSeq);

			// LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
			LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
			// 

			// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Full-Page");
		}
		// LODOP.ADD_PRINT_PDF(0, 0, '4in', '6in', base64);

		// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
		// let PRT_RES = false;
		if (IS_PREVIEW) {
			LODOP.PREVIEW();
			Notification({
				message: index + ',Opening preview...',
				type: 'success'
			});
		} else {
			// LODOP.PREVIEW();
			let PRT_RES = LODOP.PRINT();
			console.log('PRT_RES', PRT_RES);
			if (!PRT_RES) {
				url_old.error = '3';
				// reObj = url_old;
				Notification({
					message: index + ',print error;code:' + url_old.error,
					// duration: 0,
					type: 'warning'
				});
			} else {
				Notification({
					message: index + ',printing...',
					type: 'success'
				});
			}
		}

		try {
			//是否是URL
			let IS_URL = /^(https:\/\/)/.test(base64_or_url.substring(0, 10));
			if (!IS_URL) {
				//读取pdf页数
				let pdf = {};
				pdf = await pdfjsLib.getDocument({
					data: getUint8Array(base64_or_url)
				}).promise.catch(err => {
					console.log(err);
					// endLoading();
				});
				console.log('pdf', pdf);
				let numPages = pdf.numPages;
				url_old.pdfNum = numPages;
			}

		} catch (e) {
			//TODO handle the exception
			console.log(e);
		}


		// reObj = url_old;
		// endLoading();


	} else {
		// endLoading();
		url_old.error = '2';
		// reObj = url_old;
		Notification({
			message: index + ',print error;code:' + url_old.error,
			// duration: 0,
			type: 'warning'
		});
	}
	url_old.index = index;
	reObj = url_old;
	// }
	return reObj;
}

//zpl格式
export async function priterZPL_Data(option) {
	console.log('priterZPL_Data');
	//初始化
	let priterInitName = option.Prt_Init_Name; //打印任务名称
	let url_old = option.Item_Data; //需要打印的数据
	let base64_or_url = option.base64; //base64
	let printSeq = option.printSeq; //序列号
	// let printType = option.printType;//pdf还是zpl格式
	let j = option.Index; //打印的序号
	// let type = option.Type; //是否重新打印：2--重新打印，其他--首次打印
	let PRINTER_INDEX = option.PRINTER_INDEX; //指定打印机：打印机序号
	let PAGESIZE_TYPE = option.PAGESIZE_TYPE; //打印模式：缩放还是自适应
	let IS_PREVIEW = option.IS_PREVIEW; //预览打印：是否

	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	LODOP = getLodop();
	// if (LODOP) {
	// 	startLoading();
	// }
	let index = j;
	if (null == index) {
		index = url_old.sku;
	}
	let reObj = {};
	if (base64_or_url) {
		// 打印
		LODOP.PRINT_INIT(index + ':' + priterInitName);
		if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
			PRINTER_INDEX = -1;
		}
		//指定打印机
		LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);

		// let FileType = getFileType(base64_or_url);

		// // console.log('base64_or_url',base64_or_url);

		// if ('image' == FileType) { //图片
		// 	//打印图片
		// 	// ADD_PRINT_IMAGE(Top,Left,Width,Height,strHtmlContent)

		// 	LODOP.ADD_PRINT_IMAGE(0, '0.12in', '100%', '100%', `<img src='${base64_or_url}' />`);
		// 	// LODOP.ADD_PRINT_IMAGE(0,0,'100%','100%',URL);
		// 	// LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
		// } else {
		// 0.1 英寸=2.54 毫米 = 9.6像素
		// 10px=
		//左边偏移，防止左边截取 1pt=1/72英寸=0.013888 10pt =10/72in=0.13888in
		//5px->0.15in = 5px ->15px
		// LODOP.ADD_PRINT_PDF(0, '0.12in', '100%', '100%', base64_or_url);
		// }
		//zpl 数据
		LODOP.SEND_PRINT_RAWDATA(base64_or_url);
		// var PRINTSETUP_SIZE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_SIZE_HEIGHT",1);
		// console.log('PRINTSETUP_SIZE_HEIGHT',PRINTSETUP_SIZE_HEIGHT);

		// var PRINTSETUP_PAGE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_PAGE_HEIGHT",1);
		// console.log('PRINTSETUP_PAGE_HEIGHT',PRINTSETUP_PAGE_HEIGHT);
		// let typeMark = '';
		// if (1 == PAGESIZE_TYPE) {
		// 	typeMark = '';
		// } else if (2 == PAGESIZE_TYPE) {
		// 	typeMark = ',';
		// } else if (3 == PAGESIZE_TYPE) {
		// 	typeMark = '.';
		// } else {
		// 	typeMark = ';';
		// }
		//设置字体
		// FontName的值： 字符型，与操作系统字体名一致，缺省是“宋体”。
		// LODOP.SET_PRINT_STYLE('FontName', 'Sans-serif');
		// LODOP.SET_PRINT_STYLE('FontName', 'Helvetica-Bold');
		// // FontSize的值：数值型，单位是pt，缺省值是9，可以含小数，如13.5。
		// // 是一个标准的长度单位,1pt=1/72英寸,用于印刷业
		// // LODOP.SET_PRINT_STYLE('FontSize', 11);
		// // FontColor的值：整数或字符型，整数时是颜色的十进制RGB值；字符时是超文本颜色值，可以是“#”加三色16进制值组合，也可以是英文颜色名；
		// LODOP.SET_PRINT_STYLE('FontColor', '#000000');
		// // Bold的值：数字型，1代表粗体，0代表非粗体，缺省值是0。
		// LODOP.SET_PRINT_STYLE('Bold', 1);
		// // ItemType的值：数字型，0--普通项 1--页眉页脚 2--页号项 3--页数项 4--多页项 
		// // 缺省（不调用本函数时）值0。普通项只打印一次；页眉页脚项则每页都在固定位置重复打印；页号项和页数项是特殊的页眉页脚项，其内容包含当前页号和全部页数；多页项每页都打印，直到把内容打印完毕，打印时在每页上的位置和区域大小固定一样（多页项只对纯文本有效）
		// //               在页号或页数对象的文本中，有两个特殊控制字符：
		// // “#”特指“页号”，“&”特指“页数”。
		// // LODOP.SET_PRINT_STYLE('ItemType',1);
		// LODOP.SET_PRINT_STYLE('FontSize', 13);
		// LODOP.ADD_PRINT_TEXT('6in', '0.2in', "100%", "13pt", url_old.sku + " * " + url_old.quantity);
		// LODOP.SET_PRINT_STYLE('FontSize', 10);
		// LODOP.ADD_PRINT_TEXT('6in', '2.4in', "100%", "10pt", printSeq + typeMark);

		// 	if (1 == PAGESIZE_TYPE) {
		// 		// LODOP.SET_PRINT_STYLEA(0,"PDFScalMode",2);
		// 		// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', 0, '');
		// 		// LODOP.SET_PRINT_PAGESIZE(3, 0, 0, '');
		// 		// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
		// 		// LODOP.ADD_PRINT_TEXT('5.7in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
		// 		// LODOP.ADD_PRINT_TEXT('5.7in', '2.3in', "100%", "11pt", printSeq);

		// 		// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Height");
		// 		LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "91%");
		// 	} else if (2 == PAGESIZE_TYPE) {

		// 		// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', '5.8in', '');
		// 		// LODOP.ADD_PRINT_TEXT('5.45in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
		// 		// LODOP.ADD_PRINT_TEXT('5.45in', '2.3in', "100%", "11pt", printSeq);
		// 		// LODOP.SET_PRINT_STYLEA(0, "PDFScalMode", 0);
		// 		// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

		// 	} else {
		// 		// LODOP.ADD_PRINT_TEXT('6in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
		// 		// LODOP.ADD_PRINT_TEXT('6in', '2.3in', "100%", "11pt", printSeq);

		// 		// LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
		// 		LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
		// 		// 

		// 		// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Full-Page");
		// 	}
		// LODOP.ADD_PRINT_PDF(0, 0, '4in', '6in', base64);

		// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
		// let PRT_RES = false;



		if (IS_PREVIEW) {
			// LODOP.PREVIEW();
			Notification({
				message: index + ',zpl not support preview , printing..',
				type: 'warning'
			});
		} else {
			// 	// LODOP.PREVIEW();
			// 	let PRT_RES = LODOP.PRINT();
			// 	console.log('PRT_RES', PRT_RES);
			// 	if (!PRT_RES) {
			// 		url_old.error = '3';
			// 		// reObj = url_old;
			// 		Notification({
			// 			message: index + ',print error;code:' + url_old.error,
			// 			// duration: 0,
			// 			type: 'warning'
			// 		});
			// 	} else {
			Notification({
				message: index + ',printing...',
				type: 'success'
			});
			// 	}
		}

		// 	try {
		// 		//是否是URL
		// 		let IS_URL = /^(https:\/\/)/.test(base64_or_url.substring(0, 10));
		// 		if (!IS_URL) {
		// 			//读取pdf页数
		// 			let pdf = {};
		// 			pdf = await pdfjsLib.getDocument({
		// 				data: getUint8Array(base64_or_url)
		// 			}).promise.catch(err => {
		// 				console.log(err);
		// 				// endLoading();
		// 			});
		// 			console.log('pdf', pdf);
		// 			let numPages = pdf.numPages;
		// 			url_old.pdfNum = numPages;
		// 		}

		// 	} catch (e) {
		// 		//TODO handle the exception
		// 		console.log(e);
		// 	}


		// reObj = url_old;
		// endLoading();


	} else {
		// endLoading();
		url_old.error = '2';
		// reObj = url_old;
		Notification({
			message: index + ',print error;code:' + url_old.error,
			// duration: 0,
			type: 'warning'
		});
	}
	url_old.index = index;
	reObj = url_old;
	// }
	return reObj;
}

//图片，格式
// export async function priterIMG_Data(option) {
// 	//初始化
// 	let priterInitName = option.Prt_Init_Name; //打印任务名称
// 	let url_old = option.Item_Data; //需要打印的数据
// 	let base64_or_url = option.base64; //base64
// 	let printSeq = option.printSeq; //序列号
// 	// let printType = option.printType;//pdf还是zpl格式
// 	let j = option.Index; //打印的序号
// 	// let type = option.Type; //是否重新打印：2--重新打印，其他--首次打印
// 	let PRINTER_INDEX = option.PRINTER_INDEX; //指定打印机：打印机序号
// 	let PAGESIZE_TYPE = option.PAGESIZE_TYPE; //打印模式：缩放还是自适应
// 	let IS_PREVIEW = option.IS_PREVIEW; //预览打印：是否

// 	//浏览器是否支付clodop
// 	let SupportMsg = isSupport();
// 	if (!SupportMsg.support) {
// 		return;
// 	}

// 	LODOP = getLodop();
// 	// if (LODOP) {
// 	// 	startLoading();
// 	// }
// 	let index = j;
// 	if (null == index) {
// 		index = url_old.sku;
// 	}
// 	let reObj = {};
// 	if (base64_or_url) {
// 		// 打印
// 		LODOP.PRINT_INIT(index + ':' + priterInitName);
// 		if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
// 			PRINTER_INDEX = -1;
// 		}
// 		//指定打印机
// 		LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);

// 		// let FileType = getFileType(base64_or_url);

// 		// // console.log('base64_or_url',base64_or_url);

// 		// if ('image' == FileType) { //图片
// 		// 	//打印图片
// 		// 	// ADD_PRINT_IMAGE(Top,Left,Width,Height,strHtmlContent)

// 			LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%', `<img src='${base64_or_url}' />`);
// 		// 	// LODOP.ADD_PRINT_IMAGE(0,0,'100%','100%',URL);
// 		// 	// LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
// 		// } else {
// 		// 0.1 英寸=2.54 毫米 = 9.6像素
// 		// 10px=
// 		//左边偏移，防止左边截取 1pt=1/72英寸=0.013888 10pt =10/72in=0.13888in
// 		//5px->0.15in = 5px ->15px
// 		// LODOP.ADD_PRINT_PDF(0, '0.12in', '100%', '100%', base64_or_url);
// 		// }

// 		// var PRINTSETUP_SIZE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_SIZE_HEIGHT",1);
// 		// console.log('PRINTSETUP_SIZE_HEIGHT',PRINTSETUP_SIZE_HEIGHT);

// 		// var PRINTSETUP_PAGE_HEIGHT = LODOP.GET_VALUE("PRINTSETUP_PAGE_HEIGHT",1);
// 		// console.log('PRINTSETUP_PAGE_HEIGHT',PRINTSETUP_PAGE_HEIGHT);
// 		let typeMark = '';
// 		if (1 == PAGESIZE_TYPE) {
// 			typeMark = '';
// 		} else if (2 == PAGESIZE_TYPE) {
// 			typeMark = ',';
// 		} else if (3 == PAGESIZE_TYPE) {
// 			typeMark = '.';
// 		} else {
// 			typeMark = ';';
// 		}
// 		//设置字体
// 		// FontName的值： 字符型，与操作系统字体名一致，缺省是“宋体”。
// 		// LODOP.SET_PRINT_STYLE('FontName', 'Sans-serif');
// 		LODOP.SET_PRINT_STYLE('FontName', 'Helvetica-Bold');
// 		// FontSize的值：数值型，单位是pt，缺省值是9，可以含小数，如13.5。
// 		// 是一个标准的长度单位,1pt=1/72英寸,用于印刷业
// 		// LODOP.SET_PRINT_STYLE('FontSize', 11);
// 		// FontColor的值：整数或字符型，整数时是颜色的十进制RGB值；字符时是超文本颜色值，可以是“#”加三色16进制值组合，也可以是英文颜色名；
// 		LODOP.SET_PRINT_STYLE('FontColor', '#000000');
// 		// Bold的值：数字型，1代表粗体，0代表非粗体，缺省值是0。
// 		LODOP.SET_PRINT_STYLE('Bold', 1);
// 		// ItemType的值：数字型，0--普通项 1--页眉页脚 2--页号项 3--页数项 4--多页项 
// 		// 缺省（不调用本函数时）值0。普通项只打印一次；页眉页脚项则每页都在固定位置重复打印；页号项和页数项是特殊的页眉页脚项，其内容包含当前页号和全部页数；多页项每页都打印，直到把内容打印完毕，打印时在每页上的位置和区域大小固定一样（多页项只对纯文本有效）
// 		//               在页号或页数对象的文本中，有两个特殊控制字符：
// 		// “#”特指“页号”，“&”特指“页数”。
// 		// LODOP.SET_PRINT_STYLE('ItemType',1);
// 		LODOP.SET_PRINT_STYLE('FontSize', 13);
// 		LODOP.ADD_PRINT_TEXT('6in', '0.2in', "100%", "13pt", url_old.sku + " * " + url_old.quantity);
// 		LODOP.SET_PRINT_STYLE('FontSize', 10);
// 		LODOP.ADD_PRINT_TEXT('6in', '2.4in', "100%", "10pt", printSeq + typeMark);

// 		if (1 == PAGESIZE_TYPE) {
// 			// LODOP.SET_PRINT_STYLEA(0,"PDFScalMode",2);
// 			// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', 0, '');
// 			// LODOP.SET_PRINT_PAGESIZE(3, 0, 0, '');
// 			// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
// 			// LODOP.ADD_PRINT_TEXT('5.7in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
// 			// LODOP.ADD_PRINT_TEXT('5.7in', '2.3in', "100%", "11pt", printSeq);

// 			// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Auto-Height");
// 			LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "91%");
// 		} else if (2 == PAGESIZE_TYPE) {

// 			// LODOP.SET_PRINT_PAGESIZE(3, '3.8in', '5.8in', '');
// 			// LODOP.ADD_PRINT_TEXT('5.45in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
// 			// LODOP.ADD_PRINT_TEXT('5.45in', '2.3in', "100%", "11pt", printSeq);
// 			// LODOP.SET_PRINT_STYLEA(0, "PDFScalMode", 0);
// 			// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);

// 		} else {
// 			// LODOP.ADD_PRINT_TEXT('6in', '0.15in', "100%", "11pt", url_old.sku + " * " + url_old.quantity);
// 			// LODOP.ADD_PRINT_TEXT('6in', '2.3in', "100%", "11pt", printSeq);

// 			// LODOP.SET_PRINT_MODE("FULL_WIDTH_FOR_OVERFLOW", true);
// 			LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
// 			// 

// 			// LODOP.SET_PRINT_MODE("PRINT_PAGE_PERCENT", "Full-Page");
// 		}
// 		// LODOP.ADD_PRINT_PDF(0, 0, '4in', '6in', base64);

// 		// LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
// 		// let PRT_RES = false;
// 		if (IS_PREVIEW) {
// 			LODOP.PREVIEW();
// 			Notification({
// 				message: index + ',Opening preview...',
// 				type: 'success'
// 			});
// 		} else {
// 			// LODOP.PREVIEW();
// 			let PRT_RES = LODOP.PRINT();
// 			console.log('PRT_RES', PRT_RES);
// 			if (!PRT_RES) {
// 				url_old.error = '3';
// 				// reObj = url_old;
// 				Notification({
// 					message: index + ',print error;code:' + url_old.error,
// 					// duration: 0,
// 					type: 'warning'
// 				});
// 			} else {
// 				Notification({
// 					message: index + ',printing...',
// 					type: 'success'
// 				});
// 			}
// 		}

// 		// try {
// 		// 	//是否是URL
// 		// 	let IS_URL = /^(https:\/\/)/.test(base64_or_url.substring(0, 10));
// 		// 	if (!IS_URL) {
// 		// 		//读取pdf页数
// 		// 		let pdf = {};
// 		// 		pdf = await pdfjsLib.getDocument({
// 		// 			data: getUint8Array(base64_or_url)
// 		// 		}).promise.catch(err => {
// 		// 			console.log(err);
// 		// 			// endLoading();
// 		// 		});
// 		// 		console.log('pdf', pdf);
// 		// 		let numPages = pdf.numPages;
// 		// 		url_old.pdfNum = numPages;
// 		// 	}

// 		// } catch (e) {
// 		// 	//TODO handle the exception
// 		// 	console.log(e);
// 		// }


// 		// reObj = url_old;
// 		// endLoading();


// 	} else {
// 		// endLoading();
// 		url_old.error = '2';
// 		// reObj = url_old;
// 		Notification({
// 			message: index + ',print error;code:' + url_old.error,
// 			// duration: 0,
// 			type: 'warning'
// 		});
// 	}
// 	url_old.index = index;
// 	reObj = url_old;
// 	// }
// 	return reObj;
// }


// 打印Sku
// export async function priterSkuBarCode(priterInitName, CodeValue, PRINTER_INDEX, PAGESIZE_TYPE) {
// 	LODOP = getLodop();

// 	let reObj = {};
// 	// 打印
// 	LODOP.PRINT_INIT(CodeValue + ':' + priterInitName);
// 	if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
// 		PRINTER_INDEX = -1;
// 	}
// 	//指定打印机
// 	LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);
// 	// 1 英寸=25.4 毫米 = 96像素

// 	// 现在有两个尺寸 
// 	// 1. 70*30mm （国内采购的纸）
// 	// 2. 2.25*1.25inch （美国采购的纸）
// 	let Width = "100%";
// 	let Height = "100%";
// 	if (1 == PAGESIZE_TYPE) {
// 		Width = "70mm";
// 		Height = "30mm";
// 	} else if (2 == PAGESIZE_TYPE) {
// 		Width = "2.25in";
// 		Height = "1.25in";
// 	} else {

// 	}

// 	//左边偏移5px，防止左边截取
// 	// LODOP.ADD_PRINT_PDF(0, '5px', '100%', '100%', base64);
// 	LODOP.ADD_PRINT_BARCODE(0, 0,Width, Height, "128Auto", CodeValue);

// 	// LODOP.PRINT();
// 	LODOP.PREVIEW();

// 	// reObj = url_old;
// 	// endLoading();
// 	Notification({
// 		message: CodeValue + ',正在打印...',
// 		type: 'success'
// 	});

// 	// }
// 	return reObj;
// }
//SKU码-关联编号查询-打印pdf，base64
export async function priterSkuBarCode(priterInitName, CodeValue, PDF_BASE64, PRINTER_INDEX, PAGESIZE_TYPE) {
	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	LODOP = getLodop();

	let reObj = {};
	// 打印
	LODOP.PRINT_INIT(CodeValue + ':' + priterInitName);
	if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
		PRINTER_INDEX = -1;
	}
	//指定打印机
	LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);
	// 1 英寸=25.4 毫米 = 96像素

	// 现在有两个尺寸 
	// 1. 70*30mm （国内采购的纸）
	// 2. 2.25*1.25inch （美国采购的纸）
	// let Width = "100%";
	// let Height = "100%";
	// let barW = "70%";
	// let barH = "70%";
	// let barT = "0%";
	// let barL = "10%";
	// if (1 == PAGESIZE_TYPE) {
	// 	let w = 70,h=30,unit="mm";
	// 	Width = w+unit;
	// 	Height = h+unit;

	// 	barW = w*0.7+unit;
	// 	barH = h*0.7+unit;
	// 	barL = w*0.1+unit;
	// 	barT = 0;

	// } else if (2 == PAGESIZE_TYPE) {
	// 	let w = 2.25,h=1.25,unit="in";
	// 	Width = w+unit;
	// 	Height = h+unit;

	// 	barW = w*0.7+unit;
	// 	barH = h*0.7+unit;
	// 	barL = w*0.1+unit;
	// 	barT = 0;
	// } else {

	// }

	//设置纸张尺寸
	// LODOP.SET_PRINT_PAGESIZE(1,Width,Height,"");

	// let data = exportPdfByBarCode(CodeValue, PAGESIZE_TYPE);
	// console.log('data',data);
	// return;

	// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%', `<img src='${data}' />`);

	// let dataArray = [];
	// if (typeof Uint8Array !== 'undefined') {
	// if (arrybuffer) {
	// dataArray = new Uint8Array(data);
	// } else {
	// dataArray = new Uint8Array(data.length);
	// for (let i = 0; i < dataArray.length; i++) {
	// 	dataArray[i] = data.charCodeAt(i);
	// }
	// }
	// } else {

	// }
	// console.log('dataArray',dataArray);
	// let base64 = demoGetBASE64(dataArray);
	// console.log('pdfData',pdfData);
	// LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', pdfData);

	//左边偏移5px，防止左边截取

	LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', PDF_BASE64);
	// LODOP.ADD_PRINT_PDF(0, '5px', '100%', '100%', base64);
	// LODOP.ADD_PRINT_BARCODE(barT, barL,barW, barH, "128Auto", CodeValue);
	// LODOP.NEWPAGE();
	LODOP.PRINT();
	// LODOP.PREVIEW();

	// reObj = url_old;
	// endLoading();
	Notification({
		message: CodeValue + ',printing...',
		type: 'success'
	});

	// }
	return reObj;
}

//pdf打印预览，base64
// -托盘标
export async function priterPdfView(priterInitName, CodeValue, PDF_BASE64, PRINTER_INDEX,PrtType) {
	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	LODOP = getLodop();

	let reObj = {};
	// 打印
	LODOP.PRINT_INIT(priterInitName);
	if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
		PRINTER_INDEX = -1;
	}
	//指定打印机
	LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);
	// 1 英寸=25.4 毫米 = 96像素
	//左边偏移5px，防止左边截取

	LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', PDF_BASE64);
	// LODOP.ADD_PRINT_PDF(0, '5px', '100%', '100%', base64);
	// LODOP.ADD_PRINT_BARCODE(barT, barL,barW, barH, "128Auto", CodeValue);
	// LODOP.NEWPAGE();
	// LODOP.PRINT();
	if('1'==PrtType){
		LODOP.PRINT();
	} else {
		LODOP.PREVIEW();
	}
	

	// reObj = url_old;
	// endLoading();
	// Notification({
	// 	message: CodeValue + ',正在打印...',
	// 	type: 'success'
	// });

	// }
	return reObj;
}

//打印尺寸，模式--面单
export function priterSizeList() {
	return [{
			value: '1',
			label: '默认缩小',
			labelEn: 'default lessen'
		},
		{
			value: '2',
			label: '默认',
			labelEn: 'default'
		},
		{
			value: '3',
			label: '自适应高',
			labelEn: 'adaption height'
		}
	]
}

//打印尺寸，模式--sku
export function priterSizeList2() {
	return [{
			value: '1',
			label: '70*30mm'
		},
		{
			value: '2',
			label: '2.25*1.25inch'
		},
		{
			value: '3',
			label: '100%'
		}
	]
}

// 获得纸张类型名清单
export function GetPagesizesList(oPrinterName) {
	if (!oPrinterName) {
		oPrinterName = -1;
	}
	return LODOP.GET_PAGESIZES_LIST(oPrinterName, '\n');
}

//打印PDF或图片
export async function pritePdfOrImgUrl(priterInitName, index, URL, PDF_BASE64, FileType, PRINTER_INDEX) {
	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	LODOP = getLodop();
	LODOP.PRINT_INIT(index + ':' + priterInitName);
	//打印机序号
	if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
		PRINTER_INDEX = -1;
	}
	//指定打印机
	LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);

	// let hasPdf = false;

	if ('pdf' == FileType) { //PDF
		//打印PDF
		// LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', URL);

		if (!!PDF_BASE64 && PDF_BASE64.length > 10) {
			LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', PDF_BASE64);
		} else {
			LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', demoDownloadPDF(URL));
		}
		LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
	} else if ('image' == FileType) { //图片
		//打印图片
		// ADD_PRINT_IMAGE(Top,Left,Width,Height,strHtmlContent)

		LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%', `<img src='${URL}' />`);
		// LODOP.ADD_PRINT_IMAGE(0,0,'100%','100%',URL);
		LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
	} else {
		Notification({
			message: index + ',Not a picture or PDF...',
			type: 'error'
		});
		return;
	}

	LODOP.PRINT();
	// LODOP.PREVIEW();

	Notification({
		message: index + ',printing...',
		type: 'success'
	});
}

// 打印PDF url 单个--旧
export async function priterPdfUrl(priterInitName, url_old, j) {

	// LODOP = getLodop();
	// if (LODOP) {
	// 	startLoading();
	// }
	// let reObj = {};
	// // for (let j = 0; j < list.length; j++) {
	// // let url_old = list[j];
	// let base64 = null;
	// try {
	// 	base64 = await axiosDownloadPDF2(url_old.url);
	// } catch (err) {
	// 	console.log(err);
	// 	endLoading();
	// 	Notification({
	// 		message: '第 ' + j + "个PDF,读取失败!",
	// 		duration: 0,
	// 		type: 'error'
	// 	});
	// }
	// // console.log('base64', base64);
	// if (base64) {
	// 	// 打印
	// 	LODOP.PRINT_INIT((j + 1) + ':' + priterInitName);
	// 	let urlNew = "";
	// 	urlNew = base64.substring(37, base64.length - 1);
	// 	LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', urlNew);
	// 	LODOP.ADD_PRINT_TEXT('6in', '10px', "100%", "12px", url_old.sku + " * " + url_old.quantity);
	// 	LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
	// 	// LODOP.PRINT();
	// 	LODOP.PREVIEW();

	// 	//读取pdf页数
	// 	let pdf = await pdfjsLib.getDocument(base64).promise;
	// 	// console.log('pdf',pdf);
	// 	let numPages = pdf.numPages;
	// 	url_old.pdfNum = numPages;

	// 	reObj = url_old;
	// 	endLoading();
	// 	Notification({
	// 		message: '第 ' + j + '个PDF, 正在打印...',
	// 		type: 'success'
	// 	});

	// } else {
	// 	endLoading();
	// 	Notification({
	// 		message: '第 ' + j + '个PDF, 打印失败!',
	// 		duration: 0,
	// 		type: 'warning'
	// 	});
	// }
	// // }
	// return reObj;
}

// 打印PDF url list--旧
export async function priterPdfUrlList(priterInitName, list) {

	// LODOP = getLodop();
	// if (LODOP) {
	// 	startLoading();
	// }
	// let reArr = [];
	// for (let j = 0; j < list.length; j++) {
	// 	let url_old = list[j];
	// 	let base64 = null;
	// 	try {
	// 		base64 = await axiosDownloadPDF2(url_old.url);
	// 	} catch (err) {
	// 		console.log(err);
	// 		endLoading();
	// 		Notification({
	// 			message: '第 ' + (j + 1) + "个PDF,读取失败!",
	// 			duration: 0,
	// 			type: 'error'
	// 		});
	// 	}
	// 	// console.log('base64', base64);
	// 	if (base64) {
	// 		// 打印
	// 		LODOP.PRINT_INIT((j + 1) + ':' + priterInitName);
	// 		let urlNew = "";
	// 		urlNew = base64.substring(37, base64.length - 1);
	// 		LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', urlNew);
	// 		LODOP.ADD_PRINT_TEXT('6in', '10px', "100%", "12px", url_old.sku + " * " + url_old.quantity);
	// 		LODOP.SET_PRINT_MODE("FULL_HEIGHT_FOR_OVERFLOW", true);
	// 		LODOP.PRINT();
	// 		// LODOP.PREVIEW();

	// 		//读取pdf页数
	// 		let pdf = await pdfjsLib.getDocument(base64).promise;
	// 		let numPages = pdf._pdfInfo.numPages;
	// 		url_old.pdfNum = numPages;

	// 		reArr.push(url_old);

	// 		Notification({
	// 			message: '第 ' + (j + 1) + '个PDF, 正在打印...',
	// 			type: 'success'
	// 		});

	// 	} else {
	// 		Notification({
	// 			message: '第 ' + (j + 1) + '个PDF, 打印失败!',
	// 			duration: 0,
	// 			type: 'warning'
	// 		});
	// 	}
	// }
	// endLoading();
	// return reArr;

}

//本地PDF批量打印
export function priterPdfLocal(priterInitName, list, PRINTER_INDEX, TYPE) {
	LODOP = getLodop();
	for (let j = 0; j < list.length; j++) {
		LODOP.PRINT_INIT((j + 1) + ':' + priterInitName);
		// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
		let BASE64 = list[j];
		console.log(BASE64);
		BASE64 = BASE64.substring(28, BASE64.length - 1);
		// let BASE64 = 'http://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1425982666266009601_1632213780384_Mq4660XDz.pdf';
		console.log(BASE64);
		if (undefined === PRINTER_INDEX || null === PRINTER_INDEX || '' === PRINTER_INDEX) {
			PRINTER_INDEX = -1;
		}
		LODOP.SET_PRINTER_INDEX(PRINTER_INDEX);
		LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', BASE64);
		if (1 == TYPE) {
			LODOP.PREVIEW();
		} else {
			LODOP.PRINT();
		}


		Notification({
			message: (j + 1) + ' PDF, printing...',
			type: 'success'
		});
	}
}

//clodop-打印机列表
// export function Create_Printer_List(ElementById) {
// 	LODOP = getLodop();
// 	LODOP.Create_Printer_List(document.getElementById(ElementById));
// }

//获取LABEL打印机选择的值
export function getPrtVal() {
	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}
	// let hy_wms_prt_name = localStorage.getItem('hy_wms_prt_name');
	let hy_wms_prt_name_val = getStorage(hy_wms_prt_name);
	console.log('hy_wms_prt_name_val', hy_wms_prt_name_val);
	LODOP = getLodop();
	var iPrinterCount = LODOP.GET_PRINTER_COUNT();
	for (var i = 0; i < iPrinterCount; i++) {
		if (hy_wms_prt_name_val === LODOP.GET_PRINTER_NAME(i)) {
			return i;
		}
	}
	return '';
}

//保存LABEL打印机选择的值
export function savePrtVal(val) {
	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	LODOP = getLodop();

	try {
		console.log('savePrtVal val', val);
		// val 可为0 
		if (null != val && '' !== val && LODOP.GET_PRINTER_NAME(val)) {
			// console.log('LODOP.GET_PRINTER_NAME(val)',LODOP.GET_PRINTER_NAME(val));
			// localStorage.setItem('hy_wms_prt_name', LODOP.GET_PRINTER_NAME(val));
			setStorage(hy_wms_prt_name, LODOP.GET_PRINTER_NAME(val));
		}
	} catch (err) {
		console.log(err);
	}

}


//打印机列表
export function GetPrinterList() {
	//浏览器是否支付clodop
	let SupportMsg = isSupport();
	if (!SupportMsg.support) {
		return;
	}

	let printerList = [];
	LODOP = getLodop();

	var iPrinterCount = LODOP.GET_PRINTER_COUNT();
	for (var i = 0; i < iPrinterCount; i++) {
		printerList.push({
			name: LODOP.GET_PRINTER_NAME(i),
			value: i
		})
	}
	return printerList;
}

export async function priterPdfLocal2(priterInitName, list, imgSize) {
	// PDFJS.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
	// LODOP = getLodop();

	// // LODOP.PRINT_INIT(priterInitName);

	// for (let j = 0; j < list.length; j++) {
	// 	LODOP.PRINT_INIT((j + 1) + ':' + priterInitName);

	// 	// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
	// 	let BASE64 = list[j];

	// 	// 
	// 	console.log(BASE64);
	// 	// let BASE64_at = atob(BASE64.substring(28, BASE64.length - 1));
	// 	BASE64 = BASE64.substring(28, BASE64.length - 1);

	// 	// var raw = window.atob(BASE64);//这个方法在ie内核下无法正常解析。
	// 	//           var rawLength = raw.length;
	// 	//           //转换成pdf.js能直接解析的Uint8Array类型
	// 	//           var array = new Uint8Array(new ArrayBuffer(rawLength));
	// 	//           for (i = 0; i < rawLength; i++) {
	// 	//               array[i] = raw.charCodeAt(i) & 0xff;
	// 	//           }
	// 	let  BASE64_at = base64ToUint8Array(BASE64)					
	// 	// console.log(BASE64);
	// 	// let BASE64_at = atob(BASE64);

	// 	// console.log(BASE64_at);/*  */
	// 	// // let BASE64 = 'http://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1425982666266009601_1632213780384_Mq4660XDz.pdf';
	// 	// pdf2img(BASE64,j);
	// 	let allBase64 = await pdf2img({data:BASE64_at});

	// 	allBase64.forEach((BASE64,index)=>{
	// 		console.log(BASE64);

	// 		// LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', BASE64);

	// 		// SET_PRINT_PAGESIZE
	// 		// 369*576
	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img src='"+BASE64+"' width='365' height='570' />");
	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img src='"+BASE64+"' width='369' height='576' />");

	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, '4in', '6in',"<img src='"+BASE64+"' width='738' height='1134' />");

	// 		// LODOP.ADD_PRINT_HTML(0, 0, '100%', '100%',"<img src='"+BASE64+"' />");

	// 		if(imgSize){
	// 			// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img src='"+BASE64+"' />");
	// 			LODOP.ADD_PRINT_IMAGE(0, 0, imgSize.width, imgSize.height,"<img border='0' src='"+BASE64+"' />");
	// 			LODOP.SET_PRINT_STYLEA(0,"Stretch",1);
	// 		} else {
	// 			LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img border='0' src='"+BASE64+"' />");
	// 		}



	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, '4in', '6in',"<img border='0' src='"+BASE64+"' />");
	// 		// LODOP.SET_PRINT_STYLEA(0,"Stretch",1);

	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, '4in', '6in',"<img border='0' src='"+BASE64+"' />");
	// 		// LODOP.SET_PRINT_STYLEA(0,"Stretch",1);
	// 		// LODOP.PRINT();

	// 		// if(hasView){
	// 		// 	// if(j==0 && index==0){
	// 		// 		LODOP.PREVIEW();
	// 		// 	// }
	// 		// } else {
	// 			LODOP.PRINT();
	// 		// }
	// 		if(allBase64.length<=1){
	// 			Notification({
	// 				message: '第 ' + (j + 1) + '个PDF, 正在打印...',
	// 				type: 'success'
	// 			});
	// 		} else {
	// 			Notification({
	// 				message: '第 ' + (j + 1) + '个PDF, 第 '+(index+1)+' 个面单正在打印...',
	// 				type: 'success'
	// 			});
	// 		}

	// 	})
	// 	// console.log(BASE64);
	// 	// LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', BASE64);
	// 	// // LODOP.PRINT();
	// 	// LODOP.PREVIEW();
	// 	// Notification({
	// 	// 	message: '第 ' + (j + 1) + '个PDF, 正在打印...',
	// 	// 	type: 'success'
	// 	// });
	// }
}
export async function priterPdfTestLocal() {

	LODOP = getLodop();
	// for (let j = 0; j < list.length; j++) {
	LODOP.PRINT_INIT("测试");
	// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
	// let BASE64 = list[j];
	let BASE64 = '';
	// let BASE64 = 'JVBERi0xLjcNCiWhs8XXDQo4IDAgb2JqDQo8PC9UeXBlL0ZvbnREZXNjcmlwdG9yL0ZvbnROYW1lL1NpbVN1bi9GbGFncyAzMi9JdGFsaWNBbmdsZSAwL0FzY2VudCA4NTkvRGVzY2VudCAtMTQwL0ZvbnRCQm94WyAtNyAtMTQwIDEwMDAgODU5XS9DYXBIZWlnaHQgNjgzL1N0ZW1WIDA';
	// let BASE64 = 'http://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/storage/label/__1437989109030641665_1683274545280_52885088-1.pdf';
	// let BASE64 ='http://hytx-oss-wh.oss-cn-hongkong.aliyuncs.com/storage/label/__1534087848610119681_1683278691842_CS462565824-2.pdf'
	// console.log(BASE64);
	// BASE64 = BASE64.substring(28, BASE64.length - 1);
	// let BASE64 = 'http://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1425982666266009601_1632213780384_Mq4660XDz.pdf';
	// let BASE64 = 'http://sys.anmeigroup.com/label/692e64c1b5d138496fa350e2474135ff';
	// console.log(BASE64);
	// LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', BASE64);
	let prt_Data = `^XA
^FO50,50
^BY3^BCN,100,Y,N,N
^FD>;382436>6CODE39>752375152^FS
^XZ`;

	// LODOP.SEND_PRINT_RAWDATA("&l1O Hello world! ");
	LODOP.SEND_PRINT_RAWDATA(prt_Data);

	// LODOP.PRINT();
	LODOP.PREVIEW();
	Notification({
		message: '打印测试...',
		type: 'success'
	});
	// }

	// let base64test = pdf2img('./22.pdf');
	// console.log('base64test',base64test);
	// return;
	// LODOP = getLodop();
	// // let href = "http://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/__1425982666266009601_1632213666234_CS358623861.png";
	// // let url = "https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/attachment/_M1Y2-585875684251444_1030271062954991618_1632232398685_MWCmTBvMz.pdf";
	// // let url = "https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1425982666266009601_1632213666234_CS358688816.pdf";
	// // let url = "https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1425982666266009601_1632213780384_Mq4660XDz.pdf";
	// let url = "https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1032934171779751938_1631948169255_Y112-7098703-11.pdf";
	// let config_url = HY_BASEURL + "/wh/download/label/base64";
	// let params = {
	// 	"url": url
	// };
	// // let res = "";
	// // /wh/download/label  PUT   {"url":""}
	// let res = await Axios.put(config_url, params, {
	// 	// responseType: 'arraybuffer'
	// });
	// console.log('res', res);
	// // return;
	// // let href = "data:image/png;base64,";
	// // let href = "data:image/png;base64,";
	// res.data.rows.forEach((item,index)=>{
	// 	let href = "data:image/png;base64,"+item;
	// 	console.log('href', href);
	// 	LODOP.PRINT_INIT("打印测试"+index);
	// 	if(1==type){
	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, "100%", "100%","<img border='0' src='"+href+"' />");
	// 		LODOP.ADD_PRINT_IMAGE(0, 0, "4in", "6in","<img border='0' src='"+href+"' />");
	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, "4in", "6in","<img border='0' src='"+href+"' width='369' height='576' />");
	// 	} else {
	// 		// LODOP.ADD_PRINT_IMAGE(0, 0, "4in", "6in",href);
	// 	}

	// 	LODOP.SET_PRINT_STYLEA(0,"Stretch",1);
	// 	// LODOP.PREVIEW();
	// 	LODOP.PRINT();
	// });

	// LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', BASE64);
	// LODOP.PREVIEW();
	// LODOP = getLodop();
	// for (let j = 0; j < list.length; j++) {
	// 	LODOP.PRINT_INIT((j + 1) + ':' + priterInitName);
	// 	// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
	// 	let BASE64 = list[j];
	// 	console.log(BASE64);
	// 	// BASE64 = BASE64.substring(28, BASE64.length - 1);
	// 	// let BASE64 = 'http://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1425982666266009601_1632213780384_Mq4660XDz.pdf';
	// 	console.log(BASE64);
	// 	LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', BASE64);
	// 	// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img src='"+BASE64+"' width='365' height='570' />");

	// 	// LODOP.PRINT();
	// 	LODOP.PREVIEW();
	// 	Notification({
	// 		message: '第 ' + (j + 1) + '个PDF, 正在打印...',
	// 		type: 'success'
	// 	});
	// }
}

// function renderPDF2Canvas(eachPDF) {
// 	const viewport = eachPDF.getViewport(8);
// 	const canvas = document.createElement("canvas");
// 	const context = canvas.getContext('2d');
// 	canvas.height = viewport.height;
// 	canvas.width = viewport.width;
// 	const renderPromise = eachPDF.render({
// 		canvasContext: context,
// 		viewport: viewport
// 	})
// 	return {
// 		canvas,
// 		renderPromise
// 	}
// }

// function renderPDFS(allPDF) {
// 	return allPDF.map(eachPDF => {
// 		return renderPDF2Canvas(eachPDF)
// 	})
// }

// function pdf2img(pdfFile) {

//     // eslint-disable-next-line
//     return pdfjsLib.getDocument(pdfFile).then((pdf) => {
//         const numPages = pdf.numPages;
//         const allPromise = [];
//         for (let i = 1; i <= numPages; i++) {
//             allPromise.push(pdf.getPage(i))
//         }
// 				// numPages.forEach((item,index)=>{
// 				// 	allPromise.push(pdf.getPage(index))
// 				// })
//         return Promise.all(allPromise)
//     }).then((allPDF) => {
//         return renderPDFS(allPDF)
//     }).then(allRender => {
//         console.log(allRender)
//         const allCanvasOKPromises = allRender.map(render => render.renderPromise);
//         const allCanvas = allRender.map(render => render.canvas);
//         return Promise.all(allCanvasOKPromises).then(() => {
//             return allCanvas.map(canvas => {
//                 return canvas.toDataURL();
//             })
//         })
//     }).then(allBase64 => {

//         return allBase64
//     })
// }

// export function pdf2imgShow(BASE64) {
// 	BASE64 = BASE64.substring(28, BASE64.length - 1);

// 	// var raw = window.atob(BASE64);//这个方法在ie内核下无法正常解析。
// 	//           var rawLength = raw.length;
// 	//           //转换成pdf.js能直接解析的Uint8Array类型
// 	//           var array = new Uint8Array(new ArrayBuffer(rawLength));
// 	//           for (i = 0; i < rawLength; i++) {
// 	//               array[i] = raw.charCodeAt(i) & 0xff;
// 	//           }
// 	let  BASE64_at = base64ToUint8Array(BASE64)

//     // eslint-disable-next-line
//     return pdfjsLib.getDocument({data:BASE64_at}).then((pdf) => {
//         const numPages = pdf.numPages;
//         const allPromise = [];
//         for (let i = 1; i <= numPages; i++) {
//             allPromise.push(pdf.getPage(i))
//         }
//         return Promise.all(allPromise)
//     }).then((allPDF) => {
// 				console.log('allPDF',allPDF);
//         return renderPDFS(allPDF)
//     }).then(allRender => {
//         console.log(allRender)
//         const allCanvasOKPromises = allRender.map(render => render.renderPromise);
//         const allCanvas = allRender.map(render => render.canvas);
//         return Promise.all(allCanvasOKPromises).then(() => {
//             return allCanvas.map(canvas => {
//                 return canvas.toDataURL();
//             })
//         })
//     }).then(allBase64 => {
//         console.log(allBase64)
// 				// LODOP = getLodop();
// 				let BASE64 = allBase64[0];
// 				// BASE64 = BASE64.substring(22, BASE64.length - 1);
// 				// let BASE64 = 'http://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/storage/label/__1425982666266009601_1632213780384_Mq4660XDz.pdf';
// 				console.log(BASE64);
// 				// LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', BASE64);
// 				// 369*576
// 				// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img src='"+BASE64+"' width='365' height='570' />");
// 				// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img src='"+BASE64+"' width='369' height='576' />");
// 				// LODOP.ADD_PRINT_IMAGE(0, 0, '100%', '100%',"<img src='"+BASE64+"' />");

// 				// LODOP.PRINT();
// 				// LODOP.PREVIEW();
// 				// Notification({
// 				// 	message: '第 ' + (j + 1) + '个PDF, 正在打印...',
// 				// 	type: 'success'
// 				// });
//         return allBase64
//     })
// }
// function demoGetBASE64(dataArray) {
// 	var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
// 	var strData = "";
// 	for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
// 		if (isNaN(dataArray[i])) break;
// 		var b1 = dataArray[i] & 0xFF,
// 			b2 = dataArray[i + 1] & 0xFF,
// 			b3 = dataArray[i + 2] & 0xFF;
// 		var d1 = b1 >> 2,
// 			d2 = ((b1 & 3) << 4) | (b2 >> 4);
// 		var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
// 		var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
// 		strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) + digits
// 			.substring(d4, d4 + 1);
// 	}
// 	return strData;
// }

// function createCORSRequest(method, url){
//   var xhr = new XMLHttpRequest();
//   if ("withCredentials" in xhr){
//     xhr.open(method, url, true);
//   } else if (typeof XDomainRequest != "undefined"){
//     xhr = new XDomainRequest();
//     xhr.open(method, url);
//   } else {
//     xhr = null;
//   }
//   return xhr;
// }

// function demoDownloadPDF(url) {
// 	if (!(/^https?:/i.test(url))) {
// 	  return url;
// 	}
// 	// if (window.XMLHttpRequest) 
// 	var xhr = new XMLHttpRequest();
// 	// else 
// 	// // var xhr = new ActiveXObject("MSXML2.XMLHTTP");
// 	xhr.open('GET', url, false); //同步方式

// 	// var xhr = createCORSRequest("get", url, false);

// 	if (xhr.overrideMimeType) {
// 		try {
// 			xhr.responseType = 'arraybuffer';
// 			var arrybuffer = true;
// 		} catch (err) {
// 			console.log('err',err);
// 			xhr.overrideMimeType('text/plain; charset=x-user-defined');
// 		}
// 	}

// 	xhr.send(null);
// 	var data = xhr.response || xhr.responseBody;
// 	// if (typeof Uint8Array !== 'undefined') {
// 	if (arrybuffer) {
// 		var dataArray = new Uint8Array(data);
// 	} else {
// 		var dataArray = new Uint8Array(data.length);
// 		for (var i = 0; i < dataArray.length; i++) {
// 			dataArray[i] = data.charCodeAt(i);
// 		}
// 	}
// 	// } 
// 	// else
// 	// var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
// 	return demoGetBASE64(dataArray);
// }
// 合并并下载面单
// async function downloadMergeLabel(parm) {

// 	startLoading();

// 	// if (!(/^https?:/i.test(url))) {
// 	// 	return url;
// 	// }
// 	// Axios.post();
// 	// let HY_BASEURL = "/baseUrl";
// 	let config_url = HY_BASEURL + "/wh/download/pdf/merger/download";
// 	// let params = {
// 	// 	"url": url
// 	// };
// 	let res = await Axios.put(config_url, parm , {
// 		responseType: 'blob'
// 	});
// 	console.log('res', res);
// 	let url = window.URL.createObjectURL(res);
// 	// console.log(url);
// 	window.open(url, '_blank');

// 	endLoading();

// }

async function axiosDownloadPDF2(url) {
	// if (!(/^https?:/i.test(url))) {
	// 	return url;
	// }
	// Axios.post();
	// let HY_BASEURL = "/baseUrl";
	let config_url = HY_BASEURL + "/wh/download/label";
	let params = {
		"url": url
	};
	// let res = "";
	// /wh/download/label  PUT   {"url":""}
	let res = await Axios.put(config_url, params, {
		responseType: 'blob'
	});
	console.log('res', res);

	const blob = new Blob([res.data]); //处理文档流

	return await blobToDataURI(blob);
}
async function axiosDownloadPDF_ID(pdfUrl, id, httpUrl) {
	// if (!(/^https?:/i.test(url))) {
	// 	return url;
	// }
	// Axios.post();
	// let HY_BASEURL = "/baseUrl";
	let config_url = HY_BASEURL + httpUrl;
	let params = {
		"labelUrl": pdfUrl,
		"id": id,
	};
	// let res = "";
	// /wh/download/label  PUT   {"url":""}
	let res = await Axios.put(config_url, params, {
		// responseType: 'blob'
	});
	// console.log('res', res);
	return res.data;
	// const blob = new Blob([res.data]); //处理文档流

	// return await blobToDataURI(blob);
}
// async function axiosDownloadPDF_ID(pdfUrl,id,httpUrl) {
// 	// if (!(/^https?:/i.test(url))) {
// 	// 	return url;
// 	// }
// 	// Axios.post();
// 	// let HY_BASEURL = "/baseUrl";
// 	let config_url = HY_BASEURL + httpUrl;
// 	let params = {
// 		"labelUrl": pdfUrl,
// 		"id":id
// 	};
// 	// let res = "";
// 	// /wh/download/label  PUT   {"url":""}
// 	let res = await Axios.put(config_url, params, {
// 		responseType: 'blob'
// 	});
// 	console.log('res', res);

// 	const blob = new Blob([res.data]); //处理文档流

// 	return await blobToDataURI(blob);
// }
// async function axiosDownloadPDF(url) {
// 	let config_url = HY_BASEURL + "/wh/download/v2/pdf/png/pdf";
// 	let params = {
// 		"url": url
// 	};
// 	// let res = "";
// 	// /wh/download/label  PUT   {"url":""}
// 	let res = await Axios.put(config_url, params, {
// 		// responseType: 'arraybuffer'
// 	});
// 	console.log('res', res);
// 	return res.data;
// }

// async function saveBase64(base64Arr) {
// 	// if (!(/^https?:/i.test(url))) {
// 	// 	return url;
// 	// }
// 	let config_url = HY_BASEURL + "/wh/download/base64/image";
// 	let params = base64Arr;
// 	let res = await Axios.put(config_url, params, {
// 		// responseType: 'arraybuffer'
// 	});
// 	console.log('res saveBase64', res);
// 	return res.data.rows;
// }
// function blobToDataURL(blob, callback) {
//     // let a = new FileReader();
//     // a.onload = function (e) { callback(e.target.result); }
//     // a.readAsDataURL(blob);

// 		var reader = new FileReader();
// 		reader.readAsDataURL(blob);
// 		reader.onload = function(){
// 		    console.log(reader.result); //获取到base64格式图片
// 				callback(reader.result);
// 		};

// }

function blobToDataURI(blob, callback) {

	return new Promise(function(resolve, reject) {
		var reader = new FileReader();
		// console.log('reader',reader);
		reader.readAsDataURL(blob);
		reader.onload = (e) => {
			// callback(e.target.result);
			resolve(e.target.result)
		}
	});

}


export function priterPdfTest() {
	LODOP = getLodop();
	let url = 'http://localhost:8000/CLodopDemos/PDFDemo.pdf';
	// let url2 = 'https://localhost.lodop.net:8443/CLodopDemos/PDFDemo.pdf';
	// for (let j = 0; j < list.length; j++) {
	LODOP.PRINT_INIT('PDF测试页C-Lodop');
	// LODOP.SET_PRINT_PAGESIZE(3, '4in', '6in', '');
	// let BASE64 = url;
	// BASE64 = BASE64.substring(28, BASE64.length - 1);
	LODOP.ADD_PRINT_PDF(0, 0, '100%', '100%', url);
	LODOP.PREVIEW();
	// Notification({
	// 	message: '正在打印测试页...',
	// 	type: 'success'
	// });
	// }
}

function base64ToUint8Array(base64String) {
	let padding = '='.repeat((4 - base64String.length % 4) % 4);
	let base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/');

	let rawData = window.atob(base64);
	let outputArray = new Uint8Array(rawData.length);

	for (var i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}
/**
 * 函数名：getUint8Array
 * 简介：将base64 格式的字符串转换成 uint8Array （pdf.js 无法直接接受base64 格式的参数）
 * 参数：base64_string
 * return：Array
 */
function getUint8Array(base64Str) {
	let data = base64Str.replace(/[\n\r]/g, ''); // 替换多余的空格和换行
	var raw = window.atob(data);
	var rawLength = raw.length;
	var array = new Uint8Array(new ArrayBuffer(rawLength));
	for (var i = 0; i < rawLength; i++) {
		array[i] = raw.charCodeAt(i)
	}
	return array
}



function demoGetBASE64(dataArray) {
	var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
	var strData = "";
	for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
		if (isNaN(dataArray[i])) break;
		var b1 = dataArray[i] & 0xFF,
			b2 = dataArray[i + 1] & 0xFF,
			b3 = dataArray[i + 2] & 0xFF;
		var d1 = b1 >> 2,
			d2 = ((b1 & 3) << 4) | (b2 >> 4);
		var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
		var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
		strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) + digits
			.substring(d4, d4 + 1);
	}
	return strData;
}

// function demoCreatePrinterList() {         
//       setTimeout(" CreatePrinterList();","500");  //500毫秒后执行CreatePrinterList()函数,等待网页内容加载完毕。 

// }

// function demoGetLocalFileFullName() {
//     LODOP=getLodop();
//     CLODOP.On_Return = function(TaskID, Value) {
//         document.getElementById('text01').value = Value;
//     }
//     CLODOP.GET_DIALOG_VALUE("LocalFileFullName", "*.*");
// }

// function demoOpenLocalPDFfile() {
//     document.getElementById('text01').value='正在打开，请稍等...';
//     var fileInput = document.createElement('input');
//     fileInput.setAttribute('type', 'file');
//     fileInput.setAttribute('accept', 'application/pdf');
//     document.body.appendChild(fileInput);
//     fileInput.onchange=function(evt) {
//             var file = evt.target.files[0];
//             var fileReader = new FileReader();
//             fileReader.onload = function(evt) {
//                     document.getElementById('text01').value = demoGetBASE64(new Uint8Array(evt.target.result));
//                     fileInput.parentNode.removeChild(fileInput);
//             };
//             fileReader.readAsArrayBuffer(file);
//     };
//     fileInput.click();
// }

function demoDownloadPDF(url) {
	if (!(/^https?:/i.test(url))) {
		return url;
	}
	let xhr = {};
	if (window.XMLHttpRequest) {
		xhr = new XMLHttpRequest();
	}
	// else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
	xhr.open('GET', url, false); //同步方式
	let arrybuffer = false;
	if (xhr.overrideMimeType) {
		try {
			xhr.responseType = 'arraybuffer';
			arrybuffer = true;
		} catch (err) {
			xhr.overrideMimeType('text/plain; charset=x-user-defined');
		}
		xhr.send(null);
	}

	let data = xhr.response || xhr.responseBody;
	let dataArray = [];
	if (typeof Uint8Array !== 'undefined') {
		if (arrybuffer) {
			dataArray = new Uint8Array(data);
		} else {
			dataArray = new Uint8Array(data.length);
			for (let i = 0; i < dataArray.length; i++) {
				dataArray[i] = data.charCodeAt(i);
			}
		}
	} else {

	}
	// var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
	return demoGetBASE64(dataArray);
}

//    function CreatePrinterList(){
//     if (document.getElementById('Select01').innerHTML!="") return;
// 	LODOP=getLodop(); 
// 	var iPrinterCount=LODOP.GET_PRINTER_COUNT();
// 	for(var i=0;i<iPrinterCount;i++){

//   			var option=document.createElement('option');
//   			option.innerHTML=LODOP.GET_PRINTER_NAME(i);
//   			option.value=i;
// 		document.getElementById('Select01').appendChild(option);
// 	};	
// };


// Function VBS_BinaryToArray(Binary)
//     Dim i
//     ReDim byteArray(LenB(Binary))
//     For i = 1 To LenB(Binary)
//         byteArray(i-1) = AscB(MidB(Binary, i, 1))
//     Next
//     VBS_BinaryToArray = byteArray
// End Function